import { Children, cloneElement, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setPartnerData } from "app/reducers/partnerDataActionCreators";
import { logout, setTokenFromPartner, thunkSetId } from "app/pages/Auth/AuthActions";
import { setSponsorId } from "app/pages/Account/Parrainage/sponsorActionCreators";
import { updateMarketingData } from "app/reducers/marketingActionCreators";
import { useLocation, useSearchParams } from "react-router-dom";
import { parse, stringify } from "qs";
import {
	buildPartnerTokenFromQuery,
	extractPartnerDataFromQuery,
	extractPartnerTokensFromQuery,
} from "app/utils/urlDataExtractor";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { updateStrategy } from "app/reducers/partnerActionCreators";
import { updateEmail } from "app/reducers/emailActionCreators";
import { isServerSide } from "app/utils/utils";

const QueriesParser = ({
	children,
	email,
	partnerTokensNames = [],
	partnerDataNames = [],
	setPartnerData,
	thunkSetId,
	setSponsorId,
	logout,
	updateMarketingData,
	updateStrategy,
	updateEmail,
}) => {
	const [queryParsed, setQueryParsed] = useState(false);
	const [, setSearchParams] = useSearchParams();
	const { search } = useLocation();
	const query = parse(search, { ignoreQueryPrefix: true });
	const { uuid, email: emailQuery, strategy, sponsorId, ...queriesToKeep } = query;

	const {
		source,
		utm_medium,
		utm_source,
		utm_campaign,
		utm_content,
		utm_term,
		utx_medium,
		utx_source,
		utx_campaign,
		utx_content,
		utx_term,
	} = queriesToKeep;

	const partnerData = extractPartnerDataFromQuery(partnerDataNames, query);
	if (!isEmpty(partnerData)) {
		setPartnerData(partnerData);
	}

	const partnerTokenNames = partnerTokensNames || [];

	const partnerTokensInQuery = extractPartnerTokensFromQuery({
		partnerTokenNames,
		query,
	});

	const childrenWithProps = Children.map(children, child => {
		return cloneElement(child);
	});

	useEffect(() => {
		if (
			utm_medium ||
			utm_source ||
			utm_campaign ||
			utm_content ||
			source ||
			utm_term ||
			utx_medium ||
			utx_source ||
			utx_campaign ||
			utx_content ||
			utx_term
		) {
			const utmData = {};

			if (source) {
				utmData.source = source;
			}

			if (utm_medium) {
				utmData.utmMedium = utm_medium;
			}

			if (utx_medium) {
				utmData.utmMedium = utx_medium;
			}

			if (utm_source) {
				utmData.utmSource = utm_source;
			}

			if (utx_source) {
				utmData.utmSource = utx_source;
			}

			if (utm_campaign) {
				utmData.utmCampaign = utm_campaign;
			}

			if (utx_campaign) {
				utmData.utmCampaign = utx_campaign;
			}

			if (utm_content) {
				utmData.utmContent = utm_content;
			}

			if (utx_content) {
				utmData.utmContent = utx_content;
			}

			if (utm_term) {
				utmData.utmTerm = utm_term;
			}

			if (utx_term) {
				utmData.utmTerm = utx_term;
			}

			if (!isEmpty(utmData)) {
				updateMarketingData(utmData);
			}
		}

		if (uuid || emailQuery || strategy || sponsorId || !isEmpty(partnerTokensInQuery)) {
			if (!isEmpty(partnerTokensInQuery)) {
				// delete sensitive data from url to avoid user personal data to be shared
				partnerTokenNames.forEach(name => {
					delete queriesToKeep[name];
				});

				const computedPartnerToken = buildPartnerTokenFromQuery(partnerTokensInQuery);

				if (computedPartnerToken) {
					setTokenFromPartner(computedPartnerToken);
				}
			}

			if (uuid) {
				thunkSetId(uuid);
			}

			if (sponsorId) {
				setSponsorId(sponsorId);
			}

			if (emailQuery) {
				// Si l'email dans l'url diffère de celui récupéré du localstorage
				if (email && email !== emailQuery) {
					// ... alors on déconnecte l'utilisateur pour éviter qu'une réservation se fasse avec un mauvais email
					logout();
				} else {
					updateEmail(emailQuery);
				}
			}

			if (strategy) {
				updateStrategy(strategy);
			}

			const stringifyQuery = stringify({
				...queriesToKeep,
			});

			setSearchParams(stringifyQuery, { replace: true });
		}

		setQueryParsed(true);
	}, []);

	// render the component server side for SSR
	return ((queryParsed && !isServerSide) || isServerSide) && <>{childrenWithProps}</>;
};

QueriesParser.propTypes = {
	email: PropTypes.string,
	partnerTokensNames: PropTypes.array,
	partnerDataNames: PropTypes.array,
	setPartnerData: PropTypes.func,
	thunkSetId: PropTypes.func,
	setSponsorId: PropTypes.func,
	logout: PropTypes.func,
	updateMarketingData: PropTypes.func,
	updateStrategy: PropTypes.func,
	updateEmail: PropTypes.func,
};

const mapStateToProps = state => {
	return {
		partnerDataNames: state.brand.partnerDataNames,
		partnerTokensNames: state.brand.partnerTokensNames,
		email: state.email,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			setPartnerData,
			thunkSetId,
			setSponsorId,
			logout,
			updateMarketingData,
			updateStrategy,
			updateEmail,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(QueriesParser);
